import React from "react";
import theme from "theme";
import { Theme, Text, Box, Image, LinkBox, Section, Button } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
				Про компанію | Prime Auto Care Center
			</title>
			<meta name={"description"} content={"Досконалість у кожній послузі"} />
			<meta property={"og:title"} content={"Про компанію | Prime Auto Care Center"} />
			<meta property={"og:description"} content={"Досконалість у кожній послузі"} />
			<link rel={"shortcut icon"} href={"https://art.flexorise.com/img/4362878.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://art.flexorise.com/img/4362878.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://art.flexorise.com/img/4362878.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://art.flexorise.com/img/4362878.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://art.flexorise.com/img/4362878.png"} />
			<meta name={"msapplication-TileImage"} content={"https://art.flexorise.com/img/1.jpg"} />
			
		</Helmet>
		<Components.Header />
		<Section padding="80px 0 80px 0" lg-padding="60px 0 60px 0" md-padding="40px 0 40px 0" sm-padding="30px 0 30px 0">
			<Box margin="0px 0px 32px 0px" display="flex" flex-direction="column" align-items="flex-start">
				<Text
					font="--headline1"
					color="--dark"
					text-align="center"
					md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					margin="16px 0px 0px 0px"
				>
					Наші комплексні послуги
				</Text>
				<Text font="--lead" color="--darkL2" max-width="1100px">
				В автосервісі "Прайм Авто" ми пишаємося тим, що пропонуємо широкий спектр автомобільних послуг, розроблених для задоволення різноманітних потреб наших клієнтів. Незалежно від того, чи ви приїхали на профілактичний огляд, чи на капітальний ремонт, наша мета - гарантувати, що ваш автомобіль отримає найвищий рівень обслуговування. Наша команда професіоналів прагне надавати якісні послуги, які забезпечать безперебійну та ефективну роботу вашого автомобіля.
				</Text>
			</Box>
			<LinkBox
				display="flex"
				margin="0px 0px 32px 0px"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
				flex-direction="row"
				align-items="flex-start"
				flex-wrap="wrap"
				lg-margin="0px 0px 16px 0px"
			>
				<Box
					width="60%"
					padding="0px 16px 0px 0px"
					align-items="flex-start"
					display="flex"
					justify-content="flex-start"
					margin="0px 0px 0px 0px"
					md-width="100%"
					lg-width="100%"
					lg-padding="0px 0px 0px 0px"
				>
					<Image
						src="https://art.flexorise.com/img/13.jpg"
						border-radius="24px"
						max-width="100%"
						max-height="456px"
						width="100%"
						object-fit="none"
						lg-max-height="392px"
						sm-max-height="213px"
					/>
				</Box>
				<Box
					width="40%"
					padding="0px 0px 0px 16px"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					md-width="100%"
					sm-align-items="flex-start"
					sm-flex-direction="column"
					sm-justify-content="flex-start"
					flex-direction="column"
					lg-width="100%"
					lg-flex-direction="row"
					lg-justify-content="space-between"
					lg-padding="16px 12px 16px 12px"
				>
					<Box padding="0px 50px 0px 0px" lg-width="70%" sm-width="100%" lg-padding="0px 0px 0px 0px">
						<Text margin="0px 0px 8px 0px" font="--headline3" color="--dark">
						Експертні техніки до ваших послуг
						</Text>
						<Text margin="0px 0px 0px 0px" font="--lead" color="--darkL2">
						Наша команда складається з висококваліфікованих та досвідчених техніків, які закохані в автомобілі та віддані наданню виняткового сервісу. Кожен член нашої команди проходить постійне навчання, щоб бути в курсі останніх досягнень автомобільних технологій. Ми віримо, що добре доглянутий автомобіль має вирішальне значення для вашої безпеки та спокою, і ми прагнемо запропонувати найкращі рішення для всіх ваших автомобільних потреб.
						</Text>
					</Box>
				</Box>
			</LinkBox>
			<LinkBox
				display="flex"
				margin="0px 0px 32px 0px"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
				flex-direction="row"
				align-items="flex-start"
				flex-wrap="wrap"
				lg-margin="0px 0px 16px 0px"
			>
			<Box
				width="60%"
				padding="0px 0px 0px 16px"
				display="flex"
				align-items="flex-start"
				justify-content="flex-start"
				md-width="100%"
				sm-align-items="flex-start"
				sm-flex-direction="column"
				sm-justify-content="flex-start"
				flex-direction="column"
				lg-width="100%"
				lg-flex-direction="row"
				lg-justify-content="space-between"
				lg-padding="16px 12px 16px 12px"
			>
				<Box padding="0px 50px 0px 0px" lg-width="70%" lg-padding="0px 0px 0px 0px" sm-width="100%">
					<Text margin="0px 0px 8px 0px" font="--headline3" color="--dark">
					Планове технічне обслуговування
					</Text>
					<Text margin="0px 0px 0px 0px" font="--lead" color="--darkL2">
					Заміна оливи: Регулярна заміна оливи необхідна для продовження терміну служби вашого двигуна. Ми використовуємо високоякісні оливи, які відповідають специфікаціям вашого автомобіля.
Шиномонтаж: Від ротації до балансування та вирівнювання, наші шиномонтажі забезпечують плавну та безпечну їзду.
Перевірка гальм: Ваша безпека - наш пріоритет. Ми проводимо ретельні перевірки гальм і забезпечуємо необхідний ремонт, щоб убезпечити вас на дорозі.
					</Text>
				</Box>
			</Box>
				<Box
					width="40%"
					padding="0px 16px 0px 0px"
					align-items="flex-start"
					display="flex"
					justify-content="flex-start"
					margin="0px 0px 0px 0px"
					md-width="100%"
					lg-width="100%"
					lg-padding="0px 0px 0px 0px"
				>
					<Image
						src="https://art.flexorise.com/img/14.jpg"
						border-radius="24px"
						max-width="100%"
						max-height="456px"
						width="100%"
						object-fit="none"
						lg-max-height="392px"
						sm-max-height="213px"
					/>
				</Box>
			</LinkBox>
			<LinkBox
				display="flex"
				margin="0px 0px 32px 0px"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
				flex-direction="row"
				align-items="flex-start"
				flex-wrap="wrap"
				lg-margin="0px 0px 16px 0px"
			>
				<Box
					width="60%"
					padding="0px 16px 0px 0px"
					align-items="flex-start"
					display="flex"
					justify-content="flex-start"
					margin="0px 0px 0px 0px"
					md-width="100%"
					lg-width="100%"
					lg-padding="0px 0px 0px 0px"
				>
					<Image
						src="https://art.flexorise.com/img/15.jpg"
						border-radius="24px"
						max-width="100%"
						max-height="456px"
						width="100%"
						object-fit="none"
						lg-max-height="392px"
						sm-max-height="213px"
					/>
				</Box>
				<Box
					width="40%"
					padding="0px 0px 0px 16px"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					md-width="100%"
					sm-align-items="flex-start"
					sm-flex-direction="column"
					sm-justify-content="flex-start"
					flex-direction="column"
					lg-width="100%"
					lg-flex-direction="row"
					lg-justify-content="space-between"
					lg-padding="16px 12px 16px 12px"
				>
					<Box padding="0px 50px 0px 0px" lg-width="70%" lg-padding="0px 0px 0px 0px" sm-width="100%">
						<Text margin="0px 0px 8px 0px" font="--headline3" color="--dark">
						Діагностика та ремонт
						</Text>
						<Text margin="0px 0px 0px 0px" font="--lead" color="--darkL2">
						Діагностика двигуна: Використовуючи передові діагностичні інструменти, ми точно визначаємо та усуваємо проблеми з двигуном, щоб забезпечити безперебійну роботу вашого автомобіля.
Ремонт трансмісії: Наші технічні фахівці вміють вирішувати проблеми з трансмісією, забезпечуючи плавне перемикання передач і оптимальну продуктивність.
Ремонт електричної системи: Ми вирішуємо будь-які проблеми з електричними системами вашого автомобіля, від заміни акумуляторів до складного ремонту електропроводки.
						</Text>
					</Box>
				</Box>
			</LinkBox>
		</Section>
		<Section padding="64px 0 64px 0" md-padding="36px 0 36px 0" lg-padding="48px 0 48px 0" quarkly-title="Images-20">
			<Override slot="SectionContent" max-width="960px" />
			<Box
				align-items="center"
				sm-flex-direction="column-reverse"
				sm-align-items="center"
				quarkly-title="Card"
				display="flex"
				justify-content="space-between"
			>
				<Box sm-margin="0px 0px 0px 0px" max-width="450px" margin="0px 8% 0px 0px" md-margin="0px 24px 0px 0px">
					<Text font="--lead" sm-text-align="left">
					В автосервісі "Прайм Авто" ми прагнемо надавати найкращі автосервісні послуги, щоб підтримувати ваш автомобіль в ідеальному стані. Зверніться до нас сьогодні, щоб дізнатися, як ми можемо допомогти вам підтримувати продуктивність та надійність вашого автомобіля.
					</Text>
					<Button href="/contact-us" type="link" text-decoration-line="initial">
					Дізнайтеся більше 
					</Button>
				</Box>
				<Image
					md-width="50%"
					sm-margin="0px 0px 24px 0px"
					align-self="flex-end"
					src="https://art.flexorise.com/img/contact.jpg"
					object-fit="cover"
					width="45%"
					flex="0 0 auto"
				/>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});